
import Vue from "vue";
import BreadCrumb from "@/components/items/BreadcrumbCatalog.vue";


export default Vue.extend({
  name: "Home",

  components: {
    BreadCrumb,
  },
  data: () => ({
    triggerChangeDClass: 0,
  }),
  computed:{
    getDClasses(){
      return this.$store.state.dclasses;
    }
  },
});
