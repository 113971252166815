
import Vue from "vue";

export default Vue.extend({
  name: "BreadcrumbCatalog",
  data: () => ({
    items: {},
    itemsChanged: {}
  }),
  computed: {
    breadCrumbs() {
      if(this.$route.meta){
        if (typeof this.$route.meta.breadCrumb === "function") {
          let breadcrumbitems = this.$route.meta.breadCrumb.call(this, this.$route);
          breadcrumbitems = breadcrumbitems.filter(function (value: { text: undefined; }) {
            return value.text != undefined;
          });
          return breadcrumbitems;
        }
      }

      return this.$route.meta ?this.$route.meta.breadCrumb:null;
    },

  },
  methods:{
    itemschanged: function ({breadCrumbItems}: { breadCrumbItems: [] }) {
      console.log(breadCrumbItems)
      let bSlide: HTMLElement | null;
      bSlide = document.querySelector("#bSlide");
      if(bSlide){
        let options = {duration: 2000};
        this.$vuetify.goTo(bSlide, options)
      }

    }
  }
})
